import AnalyticsEvents from 'shared-ui/utils/AnalyticsEvents'
import { BrowserUtils } from '@azure/msal-browser'

export default class PortalApi {
  /**
   * @param {ApiConnector} connector
   */
  constructor(connector) {
    this.connector = connector
  }

  register = data => {
    return this.connector.post(`/@/member/register`, data)
  }

  genericMessageAction = data => {
    return this.connector.post(`/@/member/genericMessageAction`, data)
  }

  login = data => {
    return this.connector.post(`/@/member/auth`, data)
  }

  loginSSO = () => {
    return this.connector.get(`/@/sso/initsso`)
  }

  logout = async () => {
    let { request } = this.connector.post(`/@/member/logout`)
    const response = await request
    if (!response.error) window.location.reload()
  }

  messageContact = data => {
    AnalyticsEvents.pushContactEvent(data)
    return this.connector.post(`/@/message/contact`, data)
  }

  feedback = data => {
    return this.connector.post(`/@/feedback`, data)
  }

  resetPassword = data => {
    return this.connector.post(`/@/member/resetPassword`, data)
  }

  setPassword = data => {
    return this.connector.post(`/@/member/setPassword`, data)
  }

  orderReward = ({ amount, rewardId }) => {
    return this.connector.post(`/@/reward/order`, {
      form: {
        amount,
        rewardId,
      },
    })
  }

  selectDistributor = distributorCode => {
    return this.connector.post(`/@/products/distributor`, { distributorCode })
  }

  updateCart = (distributorCode, products) => {
    return this.connector.post(`/@/products/updateCart`, { distributorCode, products })
  }

  updateCartProduct = data => {
    return this.connector.post(`/@/products/updateCartProduct`, data)
  }

  verifyTrackingData = data => {
    return this.connector.post(`/@/products/verifyTrackingData`, data)
  }

  orderProducts = data => {
    return this.connector.post(`/@/products/order`, data)
  }

  cancelProductOrder = oid => {
    return this.connector.post(`/@/products/cancel`, { oid })
  }

  repeatOrder = oid => {
    return this.connector.post(`/@/products/repeat`, { oid })
  }

  orderSpecialOffer = offers => {
    return this.connector.post(`/@/specialoffers/order`, {
      form: { offers },
    })
  }

  sendRequestAnswers = data => {
    return this.connector.post(`/@/surveys/sendAnswers`, { form: data })
  }

  linkReference = data => {
    return this.connector.post(`/@/link`, data)
  }

  changePassword = data => {
    AnalyticsEvents.pushEvent('accountChange')
    return this.connector.post(`/@/member/changePassword`, data)
  }

  changeUserData = data => {
    return this.connector.post(`/@/member/changeUserData`, data)
  }

  changeProfile = data => {
    AnalyticsEvents.pushEvent('accountChange')
    return this.connector.post(`/@/member/changeProfile`, data)
  }

  changeNotificationSettings = data => {
    return this.connector.post(`/@/member/changeNotificationSettings`, data)
  }

  changeSelectedAccount = data => {
    return this.connector.post(`/@/member/changeSelectedAccount`, { form: data })
  }

  changeSettings = data => {
    return this.connector.post(`/@/member/changeSettings`, data)
  }

  changeStoreInformation = data => {
    return this.connector.post(`/@/member/changeStoreInformation`, data)
  }

  quizAnswer = data => {
    return this.connector.post(`/@/eduaction/quizanswer`, {
      form: data,
    })
  }

  quizParticipation = data => {
    return this.connector.post(`/@/eduaction/quizparticipation`, {
      form: data,
    })
  }

  notificationInteraction = data => {
    AnalyticsEvents.pushEvent('liveBox')
    return this.connector.post(`/@/notifications/interaction`, { form: data })
  }

  staff = () => {
    return this.connector.get(`/@/member/staff`)
  }

  documents = () => {
    return this.connector.get(`/@/member/documents`)
  }

  getSelfRegistrationQrCode = id => {
    return `${this.connector.baseUrl}/@/consumertrials/selfreg/qr/${id}`
  }

  downloadDocument = id => {
    window.location.href = `${this.connector.baseUrl}/@/member/documents/${id}`
  }

  downloadUserUpload = id => {
    window.location.href = `${this.connector.baseUrl}/@/member/uploads/${id}`
  }

  deleteUserUpload = id => {
    return this.connector.delete(`/@/member/uploads/${id}`)
  }

  uploadUserFile = file => {
    const formData = new FormData()
    formData.append('fileData', file)

    return this.connector.post('/@/member/uploads', formData)
  }

  timeChallenge = data => {
    return this.connector.post(`/@/eduaction/timechallenge`, {
      form: data,
    })
  }

  pointsChallenge = data => {
    return this.connector.post(`/@/eduaction/pointschallenge`, {
      form: data,
    })
  }

  uploadChallengeMultipleImages = (data, challengeId, geotag = {}) => {
    const formData = new FormData()
    if (Array.isArray(data)) {
      data.forEach(file => {
        formData.append('uploadData', file)
      })
    } else formData.append('uploadData', data)
    formData.append('challengeId', challengeId)

    let geoParsed = {}

    if (geotag.coords) {
      geoParsed = {
        accuracy: geotag.coords.accuracy,
        altitude: geotag.coords.altitude,
        altitudeAccuracy: geotag.coords.altitudeAccuracy,
        heading: geotag.coords.heading,
        latitude: geotag.coords.latitude,
        longitude: geotag.coords.longitude,
        speed: geotag.coords.speed,
        timestamp: geotag.timestamp,
      }
    }

    formData.append('geotag', JSON.stringify(geoParsed))

    return this.connector.post('/@/eduaction/uploadmany', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  createNewContact = data => {
    AnalyticsEvents.pushEvent('newAccount')
    return this.connector.post(`/@/member/createNewContact`, data)
  }

  updateContact = data => {
    return this.connector.put(`/@/member/updateContact`, data)
  }

  deleteUserInformation = data => {
    return this.connector.post(`/@/member/deleteUserInformation`, {
      form: data,
    })
  }

  deleteOtherUserInformation = data => {
    return this.connector.post(`/@/member/deleteOtherUserInformation`, {
      form: data,
    })
  }
  completeLearning = data => {
    return this.connector.post(`/@/university/completeLearning`, {
      form: data,
    })
  }

  completeTesting = data => {
    return this.connector.post(`/@/university/completeTesting`, {
      form: data,
    })
  }

  startModule = data => {
    return this.connector.post(`/@/university/startModule`, {
      form: data,
    })
  }
  getNextUniModule = data => {
    return this.connector.post(`/@/university/getNextUniModule`, {
      form: data,
    })
  }
  downloadDiploma = (universityId, memberId) => {
    window.location.href = `${this.connector.baseUrl}/api/downloadDiploma/${universityId}/${memberId}`
  }
  testAnswer = data => {
    return this.connector.post(`/@/university/testAnswer`, {
      form: data,
    })
  }
  signupWith = data => {
    return this.connector.post(`/@/member/signupWith`, {
      form: data,
    })
  }

  requestProcessCode = id => {
    return this.connector.post(`/@/member/requestProcessCode`, {
      form: { id },
    })
  }

  verifyProcessCode = data => {
    return this.connector.post(`/@/member/verifyProcessCode`, {
      form: data,
    })
  }

  requestCode = id => {
    return this.connector.post(`/@/member/requestCode`, {
      form: { id },
    })
  }

  requestCodeForConsumer = (id, value, type = 'mobilePhone') => {
    return this.connector.post(`/@/member/requestCodeForConsumer`, {
      form: { id, [type]: value },
    })
  }

  registerToken = token => {
    return this.connector.post(`/@/member/registerToken`, {
      form: { token },
    })
  }

  verifyCode = (id, verificationCode) => {
    return this.connector.post(`/@/member/verifyCode`, {
      form: { id, verificationCode },
    })
  }

  submitPinCode = (_id, code) => {
    return this.connector.post(`/@/pincode/submit`, {
      form: { _id, code },
    })
  }

  updatePackTrack = (products, surveyId) => {
    return this.connector.post(`/@/packtrack/update`, {
      form: { products, surveyId },
    })
  }

  convertPoints = data => {
    return this.connector.post(`/@/rewards/convertPoints`, {
      form: data,
    })
  }

  beAcceptDataUsage = data => {
    return this.connector.post(`/@/be/acceptDataUsage`, {
      form: data,
    })
  }

  plChangeTaxInfo = data => {
    return this.connector.post(`/@/pl/changeTaxInfo`, data)
  }

  plToggleContactStatus = data => {
    return this.connector.post(`/@/pl/toggleContactStatus`, data)
  }

  usProcessMyForm = data => {
    return this.connector.post(`/@/us/my-forms/processMyForm`, data)
  }

  validateConsumer = data => {
    return this.connector.post(`/@/consumer/validateConsumer`, data)
  }

  validateConsumerExistence = data => {
    return this.connector.post(`/@/consumer/validateConsumerExistence`, data)
  }

  profileSelection = data => {
    return this.connector.post(`/@/consumer/profileSelection`, data)
  }

  requiredDataValidation = data => {
    return this.connector.post(`/@/consumer/requiredDataValidation`, data)
  }

  submitConsumer = data => {
    return this.connector.post(`/@/consumer/submitConsumer`, data)
  }

  completeConsumerSurvey = data => {
    return this.connector.post(`/@/consumer/completeConsumerSurvey`, data)
  }

  trialSubmitConsumerCode = data => {
    return this.connector.post(`/@/consumertrials/consumerCode`, data)
  }

  trialProductFlow = data => {
    return this.connector.post(`/@/consumertrials/productFlow`, data)
  }

  trialConsumerData = data => {
    return this.connector.post(`/@/consumertrials/consumerData`, data)
  }

  validateSerialNumber = data => {
    return this.connector.post(`/@/consumertrials/validateSerialNumber`, data)
  }

  b2xRegisterNewConsumer = data => {
    return this.connector.post(`/@/b2x/registerNewConsumer`, data)
  }

  b2xSelfRegistrationQrCode = id => {
    return `${this.connector.baseUrl}/@/b2x/selfreg/qr/${id}`
  }

  b2xVerifyConsumerCode = data => {
    return this.connector.post(`/@/b2x/verifyConsumerCode`, data)
  }

  b2xResendCode = () => {
    return this.connector.post(`/@/b2x/resendConsumerCode`, {})
  }

  b2xValidateConsumerCode = data => {
    return this.connector.post(`/@/b2x/validateConsumerCode`, data)
  }

  b2xValidateProductCode = data => {
    return this.connector.post(`/@/b2x/validateProductCode`, data)
  }

  b2xAnon = data => {
    return this.connector.post(`/@/b2x/anonymousConsumer`, data)
  }

  b2xCheckout = data => {
    return this.connector.post(`/@/b2x/checkOut`, data)
  }

  promoEngineEvaluate = data => {
    return this.connector.post(`/@/promoengine/promotions`, data)
  }

  promotionsAndDiscount = data => {
    return this.connector.post(`/@/promoengine/promotionsAndDiscounts`, data)
  }

  fetchCheckoutData = data => {
    return this.connector.post(`/@/b2x/fetchCheckoutData`, data)
  }

  promoEngineRedeem = data => {
    return this.connector.post(`/@/promoengine/promotions/:offer/:rid`, data)
  }

  getPowerBiReport = sectionId =>
    this.connector.get(`/@/powerbi/getEmbedToken?sectionId=${sectionId}`)

  kzAcceptContract = data => {
    return this.connector.post(`/kz/api/acceptContract`, data)
  }

  kzRejectContract = data => {
    return this.connector.post(`/kz/api/disagreeWithContract`, data)
  }

  getUserMedia = id => {
    return this.connector.get(`/@/userChallengeMedia/${id}`)
  }

  scanPackage = data => {
    return this.connector.post(`/@/agreements/scanPackage`, data)
  }

  signContractWithOTP = data => {
    return this.connector.post(`/@/agreements/signContractWithOTP`, data)
  }

  signContractWithoutOTP = data => {
    return this.connector.post(`/@/agreements/signContractWithoutOTP`, data)
  }

  getDashboardData = (method, _id) => {
    return this.connector.get(`/@/dashboard/${method}/${_id}`)
  }

  getWholesalersByLocation = (lat, lng) => {
    return this.connector.get(`/@/wholesalers/${lat}/${lng}`)
  }

  validatePhone = async data => {
    let { request } = this.connector.post(`/@/formvalidation/validatePhone`, data)
    await request
    return request
  }

  validateFieldWithWebhook = async data => {
    let { request } = this.connector.post(`/@/formvalidation/validateFieldWithWebhook`, data)
    await request
    return request
  }
}
